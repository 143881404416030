import React from "react";
import NukaCarousel from "nuka-carousel";
import cn from "classnames";
import { useStateValue } from "@/store";
import { useI18n } from "@/plugins/i18n";
import * as css from "./carousel.modules.scss";

type CarouselProps = {
  className?: string;
  autoplay?: number;
  loop?: boolean;
  hasButtons?: boolean;
};

const Carousel: React.FC<CarouselProps> = ({
  className,
  autoplay,
  children,
  loop,
  hasButtons = true,
}) => {
  const { t } = useI18n();
  const [{ locale }] = useStateValue();
  const length = React.Children.toArray(children).filter(React.isValidElement)
    .length;

  return (
    <div className={cn(css.root, className)}>
      <NukaCarousel
        className={cn("outline-none", { "mb-6": length > 1 })}
        pauseOnHover
        wrapAround={loop}
        autoplay={!!autoplay}
        autoplayInterval={autoplay}
        edgeEasing={"easeSinOut"}
        renderBottomCenterControls={() => null}
        renderBottomLeftControls={({ currentSlide, slideCount }) =>
          length > 1 ? (
            <div className={css.counter}>
              {currentSlide + 1} {t("carousel_counter_separator")} {slideCount}
            </div>
          ) : null
        }
        renderCenterLeftControls={({ previousSlide, currentSlide }) =>
          hasButtons ? (
            <button
              style={{
                width: "100%",
                height: "100%",
                cursor: "url(/images/arrow-left.png) 0 22, auto",
              }}
              onClick={previousSlide}
              disabled={currentSlide === 0}
            />
          ) : null
        }
        renderCenterRightControls={({ nextSlide, currentSlide, slideCount }) =>
          hasButtons ? (
            <button
              style={{
                width: "100%",
                height: "100%",
                cursor: "url(/images/arrow-right.png) 57 22, auto",
              }}
              onClick={nextSlide}
              disabled={currentSlide === slideCount - 1}
            />
          ) : null
        }
        getControlsContainerStyles={(key) => {
          if (!hasButtons) {
            return null;
          }

          switch (key) {
            case "CenterLeft":
              return {
                position: "absolute",
                top: 0,
                left: 0,
                width: "45%",
                height: "100%",
                transform: "none",
              };
            case "CenterRight":
              return {
                position: "absolute",
                top: 0,
                right: 0,
                width: "45%",
                height: "100%",
                transform: "none",
              };
          }
        }}
      >
        {children}
      </NukaCarousel>
    </div>
  );
};

export default Carousel;
