import React, { useEffect, useState } from "react";

const ClientOnly = ({ children, fallback = null }) => {
  const [mounted, set] = useState(false);
  useEffect(() => set(true), []);

  return mounted ? <>{children}</> : fallback;
};

export default ClientOnly;
